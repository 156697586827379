<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">Удаление объявления</div>

<mat-dialog-content class="dialog-content">
  Вы уверены, что хотите удалить объявление?
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true">Удалить</app-button>
  <app-button variety="transparent" mat-dialog-close>Назад</app-button>
</mat-dialog-actions>
