<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">Снять объявление с публикации</div>

<mat-dialog-content class="dialog-content">
  После снятия объявления с публикации вы сможете увидеть его в разделе «Мои
  объявления»
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true"
    >Снять с публикации</app-button
  >
  <app-button variety="transparent" mat-dialog-close>Назад</app-button>
</mat-dialog-actions>
